<template lang="pug">
	.main-wrapper.transacoes-estornar
		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else)
			header.main-wrapper-header
				.p-grid.p-align-center
					.p-col-3
						Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
					.p-col-9.ta-right
						h1.text-header.text-secondary MedClub /
							<router-link :to='{ name: "transacoes-financeiras" }'> Transações</router-link> /
							<b>Estornar</b>

			Dialog.dialogPagamento(header='Adicionar forma de estorno' :modal='true' :visible.sync='dialogPagamento')
				.p-grid.p-fluid
					.p-col-5
						label.form-label Método:
						Dropdown(
							v-model='cd_forma_pagamento'
							:options='options.formasPagamento'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
						)
					.p-col-7
						label.form-label Valor:
						.p-inputgroup
							span.p-inputgroup-addon R$
							span
								currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
									v-model='nr_valor_adicionar'
									locale='pt-BR'
									:auto-decimal-mode='true'
									:value-range='{ min: 0 }')
					.p-col-12.ta-center
						Button(label='Adicionar' icon='jam jam-plus' @click='adicionar()')

			Dialog.dialogFinalizar(header='Confirmar estorno' :modal='true' :visible.sync='dialogFinalizar')
				h1.ta-center Resumo
				p <b>Pagante: </b> {{ transacao.nm_pagante }}
				p <b>CPF: </b> {{ transacao.nr_cpf_pagante_f }}
				p <b>Formas de devolução:</b>
				ul
					li(v-for='elem, idx in listEstorno' :key='idx')
						p <b>{{ elem.cd_forma_pagamento_f }}</b>: {{ elem.nr_valor_f }}
				.ta-center.my-4
					ProgressSpinner(v-if='waitingFinalizar' strokeWidth='6')
					Button.p-button-success(v-else label='Confirmar' icon='jam jam-check' @click='finalizar()')

			Panel(v-if='transacao.guias.length' header='Lista de guias')
				DataTable(:value='transacao.guias' :selection.sync="selected" dataKey="id" :class="{ selectionLock }")
					Column(selectionMode='multiple' bodyStyle='text-align: center;' headerClass='checker' bodyClass='checker' headerStyle='width: 3em')
					Column(headerStyle='width:40%' header='Paciente' field='nm_paciente')
					Column(headerStyle='width:40%' header='Estabelecimento' field='nm_estabelecimento')
					Column(headerStyle='width:20%' header='Valor' bodyStyle='text-align:center' field='nr_valortotal_f')
			
			.box-total
				p <b>Total da seleção: <span class='span-total'>{{ formatPrice(totalGuias()) }}</span></b>

			.mt-4(v-if='selected.length')

				.p-grid(v-if='selectionLock')

					.p-col-12

						Panel.mb-4(v-if='transacao.movimentos.length' header='Movimentações anteriores')
							DataTable(:value='transacao.movimentos')
								Column(headerStyle='width:80%' header='Forma de pagamento' field='ds_forma_pagamento')
								Column(headerStyle='width:20%' header='Valor' bodyStyle='text-align:center')
									template(#body='props')
										span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}

						Panel.mt-2(header='Estorno' v-if='listEstorno.length')
							.p-grid.p-fluid
								.p-col-12.p-md-3(v-for='elem, idx in listEstorno' :key='idx')
									Card.payment-box
										template(slot='content')
											.ta-right(v-if='! $parent.imprimir')
												a.btn-remove-payment(href='javascript:;' @click='listEstorno.splice(idx, 1)')
													i.jam.jam-close
											.ta-center
												div.icon-box(v-if='elem.cd_forma_pagamento == 1')
													i.pi.pi-money-bill
												div.icon-box(v-if='elem.cd_forma_pagamento == 2')
													i.jam.jam-credit-card-f
												p.text-method {{ elem.cd_forma_pagamento_f }}
												p.text-value {{ elem.nr_valor_f }}

					.p-col-6
						Button.p-button-warning(label='Alterar seleção' icon='jam jam-write' @click='selectionLock = false')

					.p-col-6.ta-right

						span.info-resta(v-if='totalEstornado() > 0 && totalEstornado() < totalGuias()') (Resta {{ formatPrice(totalGuias() - totalEstornado()) }})
						
						Button(
							v-if='totalEstornado() < totalGuias()'
							label='Forma de estorno'
							icon="jam jam-plus"
							@click='nr_valor_adicionar = 0; dialogPagamento = true'
							)
						div(v-else)
							Button.btn-finalizar.p-button-success(
								label='Finalizar'
								icon="jam jam-check"
								@click='dialogFinalizar = true'
								)

				div(v-else)

					.ta-right
						Button(label='Selecionar guias' icon='jam jam-check' @click='selectionLock = true')
</template>

<style lang="scss">
	.transacoes-estornar {
		.p-progress-spinner {
			height: 32px;
		}
		.dialogPagamento {
			width: 100%;
			max-width: 400px;
			.p-dialog-content {
				overflow: hidden;
			}
			.p-inputnumber-input {
				text-align: right;
			}
		}
		.dialogFinalizar {
			width: 96%;
			max-width: 400px;
			h1 {
				font-size: 16px;
			}
			p {
				margin: 2px 0;
			}
		}
		.box-total {
			border: 1px solid #c8c8c8;
			border-top: none;
			text-align: right;
			padding: 0.857em;
			background-color: #f4f4f4;
			.span-total {
				width: 13.5%;
				margin-left: 1%;
				display: inline-block;
				text-align: center;
			}
			p {
				margin: 0;
			}
		}
		.payment-box {
			.text-method {
				font-size: 18px;
				margin: 0;
				margin-top: 14px;
			}
			.text-value {
				font-size: 22px;
				font-weight: 700;
				margin: 0;
				margin-top: 10px;
			}
			.icon-box {
				width: 100px;
				height: 100px;
				line-height: 130px;
				border-radius: 50%;
				display: inline-block;
				background-color: #312c66; 
				i { font-size: 44px; color: #fff; }
			}
			.btn-remove-payment {
				display: inline-block;
				background-color: #c8c8c8;
				width: 20px;
				height: 20px;
				text-align: center;
				line-height: 16px;
				border-radius: 50%;
				i { font-size: 20px; color: #666; }
			}
		}
		.info-resta {
			font-size: 20px;
			margin-right: 20px;
			font-weight: 700;
			color: #888;
		}
		.selectionLock {
			.checker {
				width: 0 !important;
				overflow: hidden;
			}
		}
	}
</style>

<script>
	import Button from 'primevue/button'
	import ProgressBar from 'primevue/progressbar'
	import Panel from 'primevue/panel'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Dialog from 'primevue/dialog'
	import Dropdown from 'primevue/dropdown'
	import Card from 'primevue/card'
	import ProgressSpinner from 'primevue/progressspinner'

	import { Caixas } from '../../middleware'
	export default {
		watch: {
			selectionLock: function() {
				this.listEstorno = []
			},
			dialogPagamento: function() {
				setTimeout(() => { let el = document.querySelector('.input-adicionar');
					if (el) { let _setCursorEnd = (ev) => { if (ev.type == 'keydown' && ev.keyCode == 46)
					{ this.model.nr_valor_adicionar = 0 } let pos = el.value.length; el.setSelectionRange(pos, pos) }
					el.onclick = _setCursorEnd; el.onkeydown = _setCursorEnd }
				})
			}
		},
		created () {
			let id = 0
			let usuario = JSON.parse(localStorage.getItem('usuario'))

			if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
			if (id != 0 && usuario.nivel >= 5) {
				this.id = id
				this.waiting = true
				Caixas.getFormasPagamento().then(response => {
					if (response.status == 200) {
						if (response.data.length) {
							response.data.forEach(elem => {
								this.options.formasPagamento.push({ value: elem.id, text: elem.nm_forma_pagamento })
							})
							this.cd_forma_pagamento = this.options.formasPagamento[0].value
						}
						Caixas.findTransacao(id).then(response => {
							this.waiting = false
							if (response.status == 200) {
								response.data.guias.forEach(guia => { guia.nr_valortotal_f = this.formatPrice(guia.nr_valortotal) })
								response.data.movimentos.forEach(movimento => { movimento.nr_valor_f = this.formatPrice(movimento.nr_valor) })
								this.transacao = response.data
								if (this.transacao.nr_cpf_pagante) this.transacao.nr_cpf_pagante_f =
									this.transacao.nr_cpf_pagante.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
							}
						})
					}
				})
			} else this.$router.go(-1)
		},
		components: { Button, ProgressBar, Panel, DataTable, Column,
			Dialog, Dropdown, Card, ProgressSpinner },
		data () {
			return {
				id: 0,
				transacao: null,
				listEstorno: [],
				selected: [],
				selectionLock: false,
				nr_valor_adicionar: 0,
				cd_forma_pagamento: null,
				waiting: false,
				waitingFinalizar: false,
				totalGuias () {
					let sum = 0
					this.selected.forEach(item => sum += item.nr_valortotal)
					sum = sum.toFixed(2)
					return parseFloat(sum)
				},
				totalEstornado () {
					let sum = 0
					this.listEstorno.forEach(elem => sum += elem.nr_valor)
					sum =  sum.toFixed(2)
					return parseFloat(sum)
				},
				dialogPagamento: false,
				dialogFinalizar: false,
				options: {
					formasPagamento: []
				}
			}
		},
		methods: {
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			adicionar () {
				if (this.nr_valor_adicionar <= 0) {
					this.$toast.error('Valor deve ser superior a R$ 0,00', { duration: 3000 })
				} else if (this.nr_valor_adicionar > (this.totalGuias() - this.totalEstornado()).toFixed(2)) {
					this.$toast.error('Valor digitado maior que o valor restante', { duration: 3000 })					
				} else {
					this.listEstorno.push({
						cd_forma_pagamento: this.cd_forma_pagamento,
						cd_forma_pagamento_f: this.options.formasPagamento.find(elem => elem.value == this.cd_forma_pagamento).text,
						nr_valor: this.nr_valor_adicionar,
						nr_valor_f: this.formatPrice(this.nr_valor_adicionar)
					})
					this.dialogPagamento = false
					this.$toast.success('Forma de pagamento adicionada', { duration: 3000 })
				}
			},
			finalizar() {
				
				this.waitingFinalizar = true
				let dataSend = {
					nm_transacao_financeira: this.transacao.nm_transacao_financeira,
					guias: [],
					movimento_caixa: []
				}
				this.selected.forEach(guia => {
					dataSend.guias.push(guia.id)
				})
				this.listEstorno.forEach(movimento => {
					dataSend.movimento_caixa.push({
						cd_forma_pagamento: movimento.cd_forma_pagamento,
						nr_valor: movimento.nr_valor,
						nm_movimento_caixa: movimento.cd_forma_pagamento_f
					})
				})
				Caixas.estorno(this.id, dataSend).then(response => {
					this.waitingFinalizar = false
					if (response.status == 200) {
						this.$toast.success('Estorno realizado', { duration: 3000 })
						this.$router.push({ name: 'transacoes-financeiras' })
						this.dialogFinalizar = false
					} else if (response.status == 400)
						if (response.data.detail) {
                            if (typeof response.data.detail == 'string')
                                this.$toast.error(response.data.detail, { duration: 4000 })
                            else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 4000 }))
                        }
				})
			}
		}
	}
</script>